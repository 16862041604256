/* eslint no-unused-vars: 0 */
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { Container } from 'react-bootstrap'
import style from './styles.css'

export default () => {
  const [option, setOption] = useState(1)
  const changeOption = (e) => {
    if (e === option) {
      setOption(0)
    } else {
      window.location.href = `#position${e}`
      setOption(e)
    }
  }
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "recruit_icon.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      right: file(relativePath: { eq: "recruit_right.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      down: file(relativePath: { eq: "recruit_down.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  const expand = [
    <div>
      <div
        css={css`
          width: 100%;
          height: 1px;
          background-color: #dadbdb;
          margin: 0 auto;
        `}
      />
      <div className="positionRequires-step-content">
        <div
          css={css`
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            line-height: 28px;
          `}
        >
          · &nbsp;岗位职责
        </div>
        <div
          css={css`
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: rgba(51, 51, 51, 1);
            line-height: 30px;
            margin-top: 20px;
          `}
        >
          1、负责业务需求分析、功能拆解、系统分析、架构设计，承担核心功能、公共核心模块的设计和评审；
          <br />
          2、负责接口规范制定、技术文档编写； <br />
          3、主导团队开发人员code
          review工作，并能提供设计思路、性能优化、安全性建议 <br />
          4、带领研发人员完成项目研发任务，并指导下级开发人员，帮助其快速成长。
          <br />
          <br />
        </div>
        <div
          css={css`
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            line-height: 28px;
          `}
        >
          · &nbsp;技能要求
          <br />
          <br />
        </div>
        <div
          css={css`
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: rgba(51, 51, 51, 1);
            line-height: 30px;
            margin-top: -5px;
          `}
        >
          1、精通Laravel框架
          <br />
          2、精通Docker使用、镜像打包与编排、容器云布署
          <br />
          3、熟练使用技术管理平台（比如gitlab/github）分配任务、跟踪任务和Code
          Review
          <br />
          4、熟练使用阿里云/AWS等云产品
          <br />
          5、精通面向对象设计方法论，能够使用UML图表达系统内部设计
          <br />
          6、工作态度严谨,
          代码编写规范，主导过完整的系统开发者优先（从系统的需求、设计、架构、实现和运营）。
          <br />
          7、较强的表达和沟通能力，有责任心和持续的进取精神，团队协作意识较强，抗压能力强；
          <br />
          8、具有较高的业务敏感度，具有较强的业务架构和产品功能拆解能力，能够根据产品需求进行合理细致、边界清晰的功能模块拆解。
          <br /> <br />
          <div
            css={css`
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: bold;
              color: rgba(51, 51, 51, 1);
              line-height: 28px;
            `}
          >
            · &nbsp;加分项
          </div>
          <div
            css={css`
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: rgba(51, 51, 51, 1);
              line-height: 30px;
              margin-top: -5px;
            `}
          >
            <br />
            1、有DevOps实践经验
            <br />
            2、有K8S实践经验
            <br />
            3、有微服务治理经验
          </div>
        </div>
      </div>
    </div>,
    <div>
      <div
        css={css`
          width: 100%;
          height: 1px;
          background-color: #dadbdb;
          margin: 0 auto;
        `}
      />
      <div className="positionRequires-step-content">
        <div
          css={css`
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            line-height: 28px;
          `}
        >
          · &nbsp;技能要求：
        </div>
        <div
          css={css`
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: rgba(51, 51, 51, 1);
            line-height: 30px;
            margin-top: 20px;
          `}
        >
          1、熟练git工具的用法 <br />
          2、熟练使用Laravel框架
          <br />
          3、熟悉Linux常用命令、常用服务器搭建
          <br />
          4、熟悉Docker用法、docker-compose用法，有laradock和k8s使用经验优先
          <br />
          5、理解面向对象的基本设计准则，精通OOAD与UML并能够用UML图表达系统的内部结构优先{' '}
          <br />
          6、熟悉MySQL数据库设计、有数据增长后的处理策略和经验
          <br />
          7、熟练使用缓存、队列、调度
          <br />
          8、具备设计Restful API的能力
          <br />
          9、良好的沟通与表达能力、思路清晰，较强的动手能力与逻辑分析能力
          <br />
          10、良好的团队合作和积极主动的沟通意识
          <br />
          <br />
        </div>
        <div
          css={css`
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            line-height: 28px;
          `}
        >
          · &nbsp;加分项
        </div>
        <div
          css={css`
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: rgba(51, 51, 51, 1);
            line-height: 30px;
            margin-top: -5px;
          `}
        >
          <br />
          1、有phpunit自动化测试的使用经验
          <br />
          2、github有公开链接或者有个人博客
        </div>
      </div>
    </div>,
    <div>
      <div
        css={css`
          height: 1px;
          background-color: #dadbdb;
          margin: 0 auto;
        `}
      />
      <div className="positionRequires-step-content">
        <div
          css={css`
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            line-height: 28px;
          `}
        >
          · &nbsp;岗位职责
        </div>
        <div
          css={css`
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: rgba(51, 51, 51, 1);
            line-height: 30px;
            margin-top: 20px;
          `}
        >
          1、负责kubernetes 集群运维开发相关工作;
          <br />
          2、基于应用场景对 kubernetes 进行功能扩展二次开发 ; <br />
          3、针对应用中出现的问题能够 troubleshooting ; <br />
          4、对现有微服务进行容器化推进;
          <br />
        </div>
        <div
          css={css`
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            line-height: 28px;
          `}
        >
          <br />
          · &nbsp;任职要求
          <br />
          <br />
        </div>
        <div
          css={css`
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: rgba(51, 51, 51, 1);
            line-height: 30px;
            margin-top: -5px;
          `}
        >
          1、计算机或相关专业，本科及以上学历;
          <br />
          2、扎实的Linux基础，精通Linux操作系统的配置、管理及优化，能够独立排查及解决操作系统层面的问题；
          <br />
          3、熟悉 python/java/go 其中一种开发语言，具备运维自动化开发能力；
          <br />
          4、有2年以上专职 kubernets + Docker
          生产环境运维相关工作经验，熟悉其部署、监控及性能调优，有代码阅读能力者优先；
          <br />
          5、具备优秀的文档能力，使用文字、图示清楚地表达架构意图，能够熟练编写各类技术文档；
          <br />
          6、具备出色学习能力、善于独立思考，内驱力和主动性强，能够独当一面；具有优秀的团队合作精神、敬业精神。
          <br />
        </div>
      </div>
    </div>,
  ]
  return (
    <div className="positionRequires">
      <Container id="positionRequires">
        <div
          css={css`
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            text-align: center;
          `}
          className="positionRequires-title"
        >
          职务招聘
        </div>
        <div
          css={css`
            background: rgba(248, 248, 248, 1);
            margin: 0 auto;
            margin-bottom: 20px;
          `}
          id="position1"
          className="positionRequires-step"
        >
          <div
            css={css`
              width: 90%;
              margin: 0 auto;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                cursor: pointer;
                outline: none;
              `}
              role="button"
              onClick={() => changeOption(1)}
              onKeyDown={() => {}}
              tabIndex="0"
            >
              <Img
                fluid={data.icon.childImageSharp.fluid}
                alt=""
                className="positionRequires-step-img-left"
              />
              <div
                css={css`
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(51, 51, 51, 1);
                  margin-right: auto;
                `}
                className="positionRequires-step-title"
              >
                开发组长
              </div>
              <Img
                fluid={
                  option === 1
                    ? data.down.childImageSharp.fluid
                    : data.right.childImageSharp.fluid
                }
                alt=""
                className="positionRequires-step-img-right"
              />
            </div>
            {option === 1 ? expand[0] : []}
          </div>
        </div>
        <div
          css={css`
            background: rgba(248, 248, 248, 1);
            margin: 0 auto;
            margin-bottom: 20px;
          `}
          id="position2"
          className="positionRequires-step"
        >
          <div
            css={css`
              width: 90%;
              margin: 0 auto;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                cursor: pointer;
                outline: none;
              `}
              onClick={() => changeOption(2)}
              onKeyDown={() => {}}
              role="button"
              tabIndex="0"
            >
              <Img
                fluid={data.icon.childImageSharp.fluid}
                alt=""
                className="positionRequires-step-img-left"
              />
              <div
                css={css`
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(51, 51, 51, 1);
                  margin-right: auto;
                `}
                className="positionRequires-step-title"
              >
                PHP中级研发工程师
              </div>
              <Img
                fluid={
                  option === 2
                    ? data.down.childImageSharp.fluid
                    : data.right.childImageSharp.fluid
                }
                alt=""
                className="positionRequires-step-img-right"
              />
            </div>
            {option === 2 ? expand[1] : []}
          </div>
        </div>
        <div
          css={css`
            width: 87%;
            background: rgba(248, 248, 248, 1);
            margin: 0 auto;
            margin-bottom: 20px;
          `}
          id="position3"
          className="positionRequires-step"
        >
          <div
            css={css`
              width: 90%;
              margin: 0 auto;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                cursor: pointer;
                outline: none;
              `}
              role="button"
              tabIndex="0"
              onClick={() => changeOption(3)}
              onKeyDown={() => {}}
            >
              <Img
                fluid={data.icon.childImageSharp.fluid}
                alt=""
                className="positionRequires-step-img-left"
              />

              <div
                css={css`
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(51, 51, 51, 1);
                  margin-right: auto;
                `}
                className="positionRequires-step-title"
              >
                K8s运维工程师
              </div>
              <Img
                fluid={
                  option === 3
                    ? data.down.childImageSharp.fluid
                    : data.right.childImageSharp.fluid
                }
                alt=""
                className="positionRequires-step-img-right"
              />
            </div>
            {option === 3 ? expand[2] : []}
          </div>
        </div>
      </Container>
    </div>
  )
}
