import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Nav from '../components/nav'
import PositionRequires from '../components/recruit/positionRequires'
import Footer from '../components/footer'
import SEO from '../components/seo'

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      backgroundOperation: file(
        relativePath: { eq: "backgroundOperation.png" }
      ) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <div>
      <SEO title="人才招聘" description="recruit" />
      {/* 背景图片 */}
      <div
        css={css`
          width: 100%;
          background: url(${data.backgroundOperation.childImageSharp.fluid.src})
            center center;
          background-size: cover;
        `}
        className="recruitNav"
      >
        <Nav />
        <div
          css={css`
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              display: inline-block;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
              line-height: 78px;
              width: 100%;
              text-align: center;
            `}
            className="nav-title"
          >
            人才招聘
          </div>
        </div>
      </div>
      {/* 业务介绍 */}
      <PositionRequires />
      {/* 页脚 */}
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}
